@media 
(min-width: 769px) and
(max-width: 1223px) {
    
    #carousel-container{
        display: flex;
        margin-top: 10rem;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #car-cont1{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #car-cont1-1{
        position: relative;
        width: 40rem;
        height: 24rem;
        display: flex;
        justify-content: center;
    }

    .car-cont1-1-1{
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        position: absolute;
        z-index: 0;
    }

    #car-cont1-2{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.5rem;
    }


    .car-arrow{
        height: 2.5rem;
        cursor: pointer;
        margin: 2rem;
        margin-bottom: 8rem;
    }

    .car-image{
        height: 20rem;
        border-radius: 1rem;
    }


    .car-slogan{
        color:white;
        font-weight: bold;
        font-size: 1rem;
        margin-top: 1rem;
        text-align: center;
    }

    .car-image-selector{
        background-color: transparent;
        border-radius: 0.5rem;
        border: 0.15rem solid white;
        height: 0.5rem;
        width: 0.5rem;
        margin: 0.3rem;
        cursor: pointer;
    }
}
