@media (min-width: 1224px){
        
    #stat-container{
        background-color: white;
        display: flex;
        align-items: center;
        padding-bottom: 2rem;
        margin-bottom: 4rem;
        justify-content: center;
        overflow: visible;
    }

    #stat-cont-0{
        width: 70rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    #stat-cont-1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .stat-cont-2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .stat-value{
        font-size: 2.2rem;
        font-weight: bold;
        color: #2f4f4f;
    }

    .stat-text{
        font-size: 1.5rem;
        font-weight: bold;
        color: #2f4f4f;
    }

    #stat-cont-3{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        margin-top: 5rem;
    }

    #stat-cont-3-1{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #stat-pie-container{
        margin-right: 2rem;
    }

    #stat-pie{
        height: 100%;
        width: 100%;
        rotate: -90deg;
    }

    #stat-image{
        height: 24rem;
        position: absolute;
        right: 5rem;
        top: 7rem;
    }
}
