@media 
(min-width: 769px) and
(max-width: 1223px) {
    
    #serInf-container{
        position: absolute;
        background-color: white;
        z-index: 5;
        border-radius: 1rem;
        display: flex;
        box-shadow: 0 0.3rem 0rem 0.2rem #cbfd92;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
    }

    #serInf-cross{
        position: absolute;
        top: 1rem;
        right: 1rem;
        height: 1.5rem;
        cursor: pointer;
    }

    #serInf-title{
        color: #2f4f4f;
        font-size: 1.1rem;
        font-weight: bold;
        margin: 1rem;
        text-align: center;
    }

    #serInf-image{
        height: 8rem;
        margin: 1rem 0;
    }

    #serInf-text{
        color: #2f4f4f;
        font-size: 1rem;
        font-weight: bold;
        margin: 1rem;
        text-align: center;
        width: 20rem;
    }
}
