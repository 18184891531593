
@media (max-width: 768px){

    #carousel-container{
        display: flex;
        margin-top: 5rem;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #car-cont1{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    #car-cont1-1{
        position: relative;
        width: 44vw;
        max-width: 20rem;
        height: 65vw;
        max-height: 15rem;
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
    }

    .car-cont1-1-1{
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        position: absolute;
        z-index: 0;
    }

    #car-cont1-2{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.5rem;
    }


    .car-arrow{
        display: none;
    }

    .car-image{
        height: 40vw;
        max-height: 10rem;
        border-radius: 1rem;
    }

    .car-slogan{
        color:white;
        font-weight: bold;
        font-size: 0.9rem;
        margin-top: 1rem;
        text-align: center;
    }

    .car-image-selector{
        background-color: transparent;
        border-radius: 0.5rem;
        border: 0.1rem solid white;
        height: 0.3rem;
        width: 0.3rem;
        margin: 0.3rem;
        cursor: pointer;
    }
}