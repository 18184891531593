@media (min-width: 1224px){      

    #cont-container{
        position: relative;
        overflow: hidden;
        padding-top: 1rem;
        height: 50rem;
        margin-bottom: 1rem;
    }

    #cont-cont-0{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 0;
    }

    #cont-cont-0-1{
        display: flex;
        background-color: white;
        border-radius: 3rem;
        padding: 1rem 3rem 3rem 2rem;
        margin: 1rem;
        flex-direction: column;
        align-items: center;
        width: 50rem;
        height: 34rem;
    }

    #cont-image{
        position: absolute;
        top: -3rem;
        right: 3rem;
        height: 7rem;
        z-index: 1;
    }

    #cont-letter{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #cont-title{
        font-weight: bold;
        font-size: 2rem;
        color: #2f4f4f;
        margin: 1rem;
    }

    #cont-cont-1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .cont-input{
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cont-label, .cont-cont-policy-label{
        color: #2f4f4f;
        font-weight: bold;
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
    }

    .cont-input-text{
        border: 0.1rem solid #2f4f4f;
        border-radius: 0.7rem;
        color: #2f4f4f;
        font-size: 1rem;
        font-weight: bold;
        padding: 0.2rem;
        text-align: center;
        background-color: #f9f9f9;
    }

    #cont-cont-2{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    #cont-cont-2-1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .cont-input-select{
        border: 0.1rem solid #2f4f4f;
        border-radius: 0.5rem;
        color: #2f4f4f;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        background-color: #f9f9f9;
        padding: 0.2rem;
        cursor: pointer;
    }

    .cont-radio{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 1rem;
    }

    .cont-cont-radio{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cont-input-radio, .cont-cont-policy-input-radio{
        cursor: pointer;
        width: 1.1rem;
        height: 1.1rem;
        margin: 0 2rem 0 0.5rem;
        accent-color: #2f4f4f;
        background-color: #f9f9f9;
    }

    .cont-button{
        background-color: white;
        border-radius: 1rem;
        padding: 1rem 2rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #2f4f4f;
        cursor: pointer;
    }

    #cont-input-request{
        height: 8rem;
        width: 30rem;
        text-align: left;
        margin-top: 0.5rem;
    }

    #cont-cont-3{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 1rem;
    }

    .cont-cont-policy{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .cont-cont-policy-radio{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1rem;
    }

    .cont-cont-policy-text{
        font-size: 0.7rem;
        color: #2f4f4f;
        margin: 0 1rem 1rem 1rem;
    }

    #cont-cont-policy-confirm{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 1rem;
    }
    .cont-cont-policy-input-radio{
        margin: 0;
        margin-left: 0.2rem;
    }

    .cont-cont-policy-label{
        margin: 0;
        margin-right: 2rem;
        margin-left: 0.5rem;
    }

    #cont-cont-policy-checkbox{
        height: 1.1rem;
        width: 1.1rem;
        cursor: pointer;
        accent-color: #2f4f4f;
    }

    #cont-cont-policy-link{
        font-size: 1.1rem;
        color: #2f4f4f; 
        text-decoration: underline;
        cursor: pointer;
    }
}
