body::-webkit-scrollbar {
    display: none; /* Chrome */
}

body {
    background-color: #2f4f4f;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-x: hidden;
}
