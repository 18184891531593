
@media (max-width: 768px){

    #conts-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    #conts-title{
        color:  white;
        font-size: 1.5rem;
        font-weight: bold;
    }

    #conts-separator{
        width: 70vw;
        height: 0.1rem;
        border-radius: 0.2rem;
        background-color: white;
    }

    #conts-cont-1{
        width: 100%;
        height: 20rem;
        position: relative;
        margin-top: 2rem;
    }

    #conts-cont-2{
        margin-top: 1rem;
        display: flex;
        width: 50rem;
        flex-direction: column;
        align-items: center;
    }

    .conts-items{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0.5rem;
    }

    .conts-items-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.5rem;
    }

    .conts-items-image{
        height: 2rem;
    }

    .conts-items-title{
        color: white;
        font-size: 0.9rem;
        font-weight: bold;
        margin: 0.5rem;
    }

    .conts-items-list{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .conts-item-cont{ 
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .conts-item-name{
        font-weight: bold;
        font-size: 0.7rem;
        color: white;
        text-decoration: none;
        margin: 0.3rem;
    }

    .conts-item-mail{
        font-size: 0.7rem;
        color: white;
        cursor: pointer;
        text-decoration: none;
        margin: 0.3rem;
    }

    .conts-item-tel{
        font-size: 0.7rem;
        color: white;
        cursor: pointer;
        text-decoration: none;
        margin: 0.3rem;
    }

    #conts-map{
        border: 0;
    }

    .conts-item-social{
        height: 2rem;
        cursor: pointer;
        margin: 0 0.5rem;
    }

    #conts-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 2rem;
        width: 80vw;
        position: relative;
    }

    #conts-footer-separator{
        width: 100%;
        height: 0.05rem;
        border-radius: 0.1rem;
        background-color: white;
        margin: 0.1rem;
        margin-top: 1rem;
    }

    #conts-footer-items{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    #conts-footer-firm{
        font-size: 0.8rem;
        color: white;
        opacity: 0.8;
    }

    .conts-footer-policy{
        font-size: 0.8rem;
        color: white;
        text-decoration: underline ;
        cursor: pointer;
    }

    #conts-footer-policy-cont{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}