
@media 
(min-width: 769px) and
(max-width: 1223px) {

    #nav-menu-container{
        display: none;
    }

    #nav-cont-3{
        display: none;
    }

    #nav-container{
        background-color: white;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        z-index: 10;
        opacity: 0.9;
    }

    #nav-cont1{
        display: flex;
    }

    #nav-logo{
        max-width: 18rem;
        width: 30vw;
        cursor: pointer;
        margin-left: 2rem;
    }

    #nav-cont2{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    #nav-cont2-1{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.5rem;
    }

    #nav-cont2-2{
        display: flex;
    }

    #nav-receptions-cont{
        display: flex;
        margin: 0 1rem;
        flex-direction: column;
        align-items: center;
    }

    .nav-receptions-label{
        color: #2f4f4f;
        font-size: 0.7rem;
        font-weight: bold;
    }

    .nav-social-link{
        height: 1.2rem;
    }
    
    .nav-social-image{
        height: 1.2rem;
        margin: 0 0.2rem;
        cursor: pointer;
    }

    .nav-label{
        color: #2f4f4f;
        font-size: 1rem;
        font-weight: bold;
        margin: 1rem;
        cursor: pointer;
        margin-top: 2rem;
    }

    #nav-button{
        display: none;
    }

    #nav-greenNumber-cont{
        position: relative;
        height: 2rem;
        width: 6rem;
    }

    #nav-greenNumber-image{
        position: absolute;
        height: 2.2rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #nav-greenNumber-text{
        position: absolute;
        color: #008037;
        font-size: 0.7rem;
        font-weight: bold;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-left: 0.4rem;
    }
}
