@media (min-width: 1224px){

    #cs-container{
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        padding-top: 3rem;
        padding-bottom: 5rem;
    }

    .cs-cont{
        position: relative;
        display: flex;
        justify-content: center;
    }

    .cs-obscurator{
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
        cursor:pointer;
    }

    .cs-cont-sx{
        display: flex;
        width: 70rem;
        flex-direction: row;
        align-items: center;
        z-index: 0;
    }

    .cs-cont-dx{
        display: flex;
        width: 70rem;
        flex-direction: row-reverse;
        align-items: center;
        z-index: 0;
    }


    #cs-title{
        color:  #2f4f4f;
        font-size: 3rem;
        font-weight: bold;
    }

    #cs-separator{
        width: 80vw;
        height: 0.2rem;
        border-radius: 0.2rem;
        background-color: #2f4f4f;
    }

    .cs-text{
        display: block;
        text-align: center;
        font-weight: bold;
        color: #2f4f4f;
        padding: 0 4rem;
        font-size: 1.2rem;
    }

    .cs-image{
        max-height: 20rem;
        height: 23vw;
    }

    #cs-mission{
        background-color: #2f4f4f;
        display: flex;
        border-radius: 2rem;
        flex-direction: column;
        align-items: center;
        margin: 2rem;
        padding: 2rem;
        width: 60vw;
    }

    #cs-mission-1{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #cs-mission-title{
        font-size: 1.5rem;
        font-weight: bold;
        color: white;
        margin-right: 1rem;
    }

    #cs-mission-cup{
        height: 5rem;
    }

    #cs-mission-text{
        font-size: 1.2rem;
        color: white;
        text-align: center;
        margin: 2rem 0;
        padding: 0 5rem;
    }

    #cs-mission-image{
        height: 25rem;
        border-radius: 1rem;   
        margin-bottom: 2rem;
    }
}
