@media (min-width: 1224px){

    #login-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 16;
    }

    #login-cont-0{
        background-color: white;
        border: 0.2rem solid #2f4f4f;
        border-radius: 1rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #login-cont-1{
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    #login-cont-1-1{
        display: flex;
        justify-content: flex-end;
    }

    #login-cont-1-2{
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    #login-cross-image{
        height: 1.5rem;
        cursor: pointer;
    }

    #login-info-image{
        height: 10rem;
    }

    #login-auth{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        width: 20rem;
    }

    .login-auth-cont{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0.5rem;
    }

    .login-auth-label{
        font-size: 1rem;
        font-weight: bold;
        color: #2f4f4f;
        text-align: center;
        margin-right: 0.5rem;
    }

    .login-auth-input{

        border: 0.1rem solid #2f4f4f;
        border-radius: 0.5rem;
        color: #2f4f4f;
        font-size: 1rem;
        font-weight: bold;
        padding: 0.2rem;
        text-align: center;
        background-color: #f9f9f9;
    }

    #login-button{
        background-color: white;
        border-radius: 0.8rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: bold;
        color: #2f4f4f;
        cursor: pointer;
        margin-top: 1rem;
    }

    #login-message{
        font-size: 0.8rem;
        font-weight: bold;
        color: red;
        text-align: center;
        margin-top: 0.5rem;
    }
}
