
@media (max-width: 768px){

    #stat-container{
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #stat-cont-0{
        width: 70rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    #stat-cont-1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .stat-cont-2{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0.5rem;
    }

    .stat-value{
        font-size: 1.5rem;
        font-weight: bold;
        color: #2f4f4f;
    }

    .stat-text{
        font-size: 1rem;
        font-weight: bold;
        color: #2f4f4f;
        text-align: center;
    }

    #stat-cont-3{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
    }

    #stat-cont-3-1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    #stat-pie-container{
        margin-right: 0.5rem;
    }

    #stat-pie{
        height: 100%;
        width: 100%;
        rotate: -90deg;
    }

    #stat-image{
        height: 7rem;
        position: absolute;
        right: 0rem;
        top: 4rem;
    }
}