@media 
(min-width: 769px) and
(max-width: 1223px) {

    #alert-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 16;
    }

    #alert-cont-0{
        background-color: white;
        border: 0.2rem solid #2f4f4f;
        border-radius: 1rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #alert-cont-1{
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    #alert-cont-1-1{
        display: flex;
        justify-content: flex-end;
    }

    #alert-cont-1-2{
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    #alert-cross-image{
        height: 1.5rem;
        cursor: pointer;
    }

    #alert-info-image{
        height: 8rem;
    }

    #alert-text{
        font-size: 1rem;
        font-weight: bold;
        color: #2f4f4f;
        padding: 1rem;
        text-align: center;
    }
}
