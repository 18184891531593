
@media (max-width: 768px){
    
    #cs-container{
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }
    
    .cs-cont{
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
    }
    
    .cs-obscurator{
        z-index: 1;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        cursor:pointer;
    }
    
    .cs-cont-sx{
        display: flex;
        width: 20rem;
        flex-direction: column-reverse;
        align-items: center;
        z-index: 0;
    }
    
    .cs-cont-dx{
        display: flex;
        width: 20rem;
        flex-direction: column-reverse;
        align-items: center;
        z-index: 0;
    }
    
    
    #cs-title{
        color:  #2f4f4f;
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 1rem;
    }
    
    #cs-separator{
        width: 70vw;
        height: 0.1rem;
        border-radius: 0.2rem;
        background-color: #2f4f4f;
    }
    
    .cs-text{
        display: block;
        text-align: center;
        font-weight: bold;
        color: #2f4f4f;
        padding: 0 1rem;
        font-size: 0.8rem;
    }
    
    .cs-image{
        height: 10rem;
    }
    
    #cs-mission{
        background-color: #2f4f4f;
        display: flex;
        border-radius: 2rem;
        flex-direction: column;
        align-items: center;
        margin: 1rem;
        padding: 1rem;
        width: 80vw;
    }
    
    #cs-mission-1{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    #cs-mission-title{
        font-size: 1rem;
        font-weight: bold;
        color: white;
        margin-right: 1rem;
    }
    
    #cs-mission-cup{
        height: 2.5rem;
    }
    
    #cs-mission-text{
        font-size: 0.8rem;
        color: white;
        text-align: center;
        margin: 1rem 0;
        padding: 0 1rem;
    }
    
    #cs-mission-image{
        height: 10rem;
        border-radius: 1rem;   
        margin-bottom: 2rem;
    }
    
}



