
@media (max-width: 768px){
       
    #ser-container{
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    #ser-cont-0{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #ser-title{
        color: white;
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 1rem;
    }

    #ser-separator{
        width: 20rem;
        height: 0.1rem;
        border-radius: 0.2rem;
        background-color: white;
    }

    #ser-cont-1{
        position: relative;
        width: 100vw;
        height: 140vw;
        max-height: 32rem;
        margin: 2rem 0 4rem 0;
    }

    #ser-image1{
        position: absolute;
        height: 8rem;
        top: 12rem;
        right: -5rem;
        z-index: 0;
    }

    #ser-image2{
        position: absolute;
        height: 8rem;
        bottom: -3rem;
        left: -5rem;
        z-index: 0;
    }

    #ser-cont-2{
        position: absolute;
        max-width: 22rem;
        width: 95vw;
        max-height: 30rem;
        height: 123vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: 1;
    }

    .ser-cont-service{
        display: flex;
        border-radius: 1rem;
        background-color: white;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        width: 33vw;
        max-width: 8rem;
        height: 43vw;
        max-height: 10.5rem;
        cursor: pointer;
        position: absolute;
    }

    .ser-service-image{
        max-height: 5rem;
        height: 20vw;
        margin-top: 1rem;
    }

    .ser-service-text{
        font-size: 0.8rem;
        font-weight: bold;
        color: #2f4f4f;
        text-align: center;
        margin-top: 1rem;
    }

    #ser-cont-logo{
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    #ser-cont-logo img{
        width: 15vw;
        max-width: 3.5rem;
    }
}