
@media (max-width: 768px){

    #nav-container{
        background-color: white;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        flex-wrap: nowrap;
        align-items: center;
        z-index: 10;
        opacity: 0.9;
        flex-direction: column;
        padding: 1rem 0;
    }

    #nav-menu-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 9;
    }

    #nav-menu-listener{
        position: absolute;
        background-color: black;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9;
    }

    #nav-cont1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    #nav-logo{
        width: 50vw;
        cursor: pointer;
        margin-left: 1rem;
        max-width: 13.5rem;
    }

    #nav-cont2{
        display: none;
    }

    #nav-menu{
        display: flex;
        background-color: white;
        position: absolute;
        z-index: 10;
        top: 6rem;
        right: -1rem;
        opacity: 1;
        width: 60vw;
        padding: 1rem;
        border-radius: 1rem;
        padding-right: 2rem;
        flex-direction: column;
        align-items: center;
        opacity: 1;
    }

    #nav-menu-header{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 1rem;
        align-items: center;
    }

    #nav-receptions-cont{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.5rem;
    }

    .nav-header-label{
        font-size: 0.6rem;
        color: #2f4f4f;
    }

    #nav-menu-list{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-menu-label{
        color: #2f4f4f;
        font-size: 1rem;
        font-weight: bold;
        margin: 1rem 0;
    }

    #nav-menu-social{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .nav-menu-social-image{
        height: 1.2rem;
        margin: 0.5rem;
    }

    #nav-menu-separator{
        background-color: #2f4f4f;
        width: 80%;
        height: 0.1rem;
        border-radius: 1rem;
    }

    #nav-button{
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        margin-right: 1.5rem;
        max-height: 2rem;
        max-width: 2rem;
        width: 9vw;
        height: 9vw;
        background-color: white;
        border-radius: 0.5rem;
        border: 0.1rem solid #2f4f4f;
    }

    #nav-button-image{
       max-height: 1rem;
       height: 5vw;
    }

    #nav-greenNumber-cont{
        position: relative;
        height: 2rem;
    }

    #nav-greenNumber-image{
        position: absolute;
        height: 1.9rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #nav-greenNumber-text{
        position: absolute;
        color: #008037;
        font-size: 0.6rem;
        font-weight: bold;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-left: 0.4rem;
    }
}
