@media 
(min-width: 769px) and
(max-width: 1223px) {
        
    #ser-container{
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    #ser-cont-0{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #ser-title{
        color:  white;
        font-size: 2.5rem;
        font-weight: bold;
    }

    #ser-separator{
        width: 80vw;
        height: 0.2rem;
        border-radius: 0.2rem;
        background-color: white;
    }

    #ser-cont-1{
        position: relative;
        width: 100vw;
        height: 41rem;
        margin: 2rem 0 0 1rem;
    }

    #ser-image1{
        position: absolute;
        height: 15rem;
        top: 5rem;
        right: -12rem;
        z-index: 0;
    }

    #ser-image2{
        position: absolute;
        height: 18rem;
        bottom: 0;
        left: -10rem;
        z-index: 0;
    }


    #ser-cont-2{
        position: absolute;
        width: 35rem;
        height: 35rem;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: 1;
    }

    .ser-cont-service{
        display: flex;
        border-radius: 1rem;
        background-color: white;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        width: 12rem;
        height: 13.5rem;
        cursor: pointer;
        position: absolute;
    }

    .ser-service-image{
        height: 7rem;
        margin-top: 1rem;
    }

    .ser-service-text{
        font-size: 1rem;
        font-weight: bold;
        color: #2f4f4f;
        text-align: center;
        margin-top: 2rem;
    }

    #ser-cont-logo{
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    #ser-cont-logo img{
        height: 5rem;
    }

}
